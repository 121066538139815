module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Caring Personnel","short_name":"Caring Personnel","description":"Caring Personnel serwis dla opiekunek osób starszych","start_url":"/","background_color":"#fff","theme_color":"#364a95","display":"standalone","legacy":true,"prefer_related_applications":true,"icon":"content/assets/icon.png","icons":[{"src":"content/assets/maskable-icon.png","sizes":"192x192","type":"image/png"},{"src":"content/assets/maskable-icon-x512.png","sizes":"512x512","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bb113c8faa7a9238f669ef388203ac62"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
