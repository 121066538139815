/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@fontsource/open-sans';
import './src/sass/common.scss';

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  // if (!(`IntersectionObserver` in window)) {
  //     import(`intersection-observer`);
  //     //console.log(`# IntersectionObserver is polyfilled!`)
  // }
  //if (window.location.hostname.includes('localhost')) {
  const link = document.createElement('link');
  link.rel = 'preconnect';
  link.href = 'https://www.google-analytics.com';
  document.head.appendChild(link);

  const link2 = document.createElement('link');
  link2.rel = 'preconnect';
  link2.href = 'https://fonts.gstatic.com';
  document.head.appendChild(link2);

  const link3 = document.createElement('link');
  link3.rel = 'preconnect';
  link3.href = 'https://www.chater.biz';
  document.head.appendChild(link3);

  const link4 = document.createElement('link');
  link4.rel = 'preconnect';
  link4.href = 'https://www.clarity.ms';
  document.head.appendChild(link4);
  //}
};

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload();

export const disableCorePrefetching = () => true;
